<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div>
        <v-card
          :to="'/product/' + product.product_id"
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
        >
          <v-img
            :aspect-ratio="16 / 9"
            :src="getImage(product.image)"
            height="180px"
            class="mb-n3"
          >
          </v-img>
          <v-card-title class="text-caption text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1">
            <p v-line-clamp:20="2" class="mb-0"><b>{{ product.brand }} {{ product.type }}
            {{ product.product_name }} ({{ product.product_code }})</b></p>
          </v-card-title>
          <v-card-subtitle class="text-caption text-sm-body-3 text-md-body-2 text-lg-body-2 text-xl-body-2 headerClass">
            <small>{{ product.category }}</small>
          </v-card-subtitle>
          <v-divider class="mt-n3"></v-divider>
          <v-card-actions class="text-caption text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1">
            <v-icon small>mdi-format-list-bulleted</v-icon>
            <small>Stok {{ product.stock }}</small>
            <v-spacer></v-spacer>
            <v-icon small>mdi-eye</v-icon>
            <small>{{ product.views_count }}</small>
          </v-card-actions>
        </v-card>
        <v-expand-transition>
          <div
            v-if="hover"
            class="
              d-flex
              transition-fast-in-fast-out
              gray
              v-card--reveal
              text-h2
              white--text
            "
            style="height: 100%"
          >
            <v-btn
              block
              color="success"
              @click="buy"
              :disabled="product.stock == 0"
              style="width:100%"
              class="text-caption text-sm-body-2 text-md-body-1 text-lg-body-1 text-xl-body-1"
            >
              <small><b><v-icon>mdi-cart-plus</v-icon> &nbsp; Masukkan Keranjang</b></small>
            </v-btn>
          </div>
        </v-expand-transition>
      </div>
    </v-hover>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "book-item",
  props: ["product"],
  data: () => ({}),

  methods: {
    ...mapActions({
      addCart: "cart/add",
      setAlert: "alert/set",
    }),
    buy() {
      // alert('buy')
      // this.$store.dispatch('add', this.product)
      this.addCart(this.product);

      this.setAlert({
        status: true,
        color: "success",
        text: "Produk telah ditambahkan ke keranjang",
      });
    },
  },
};
</script>

<style scoped>
/* .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
} */
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.headerClass{
    white-space: nowrap ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
}
</style>